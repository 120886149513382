import React from 'react';
import imgBg1 from '../assets/portada300424.jpg'

const Banner = () => {
  return (
    <div className="BannerContainer">
        <div className="img-bg"><img src={imgBg1} alt="Banner Principal" /></div>
        <div className="container-section">
            <div className="title-seccion">
                <h1>EXPERTS IN ANY <span>TYPE OF CLEANING</span></h1>
            </div>
        </div>
    </div>
  );
};

export default Banner;