import React, {ReactNode} from 'react';
import Header from './Header';
import Footer from './Footer';

interface LayoutProps {
    children: ReactNode;
    regular?: boolean;
}

const Layout = ({ children, regular }: LayoutProps) => {
  return (
    <div>
      <Header regular={regular}/>
      <main>
        {children}
      </main>
      <Footer/>
    </div>
  );
};

export default Layout;