import React from 'react';
import Logo from '../assets/LogoGreenSun.svg';
import { PinDropRounded, LocalPostOfficeRounded } from '@mui/icons-material';
import iconWp from '../assets/iconWp.png';
import iconFb from '../assets/iconFb.png';
import iconIg from '../assets/iconIg.png';
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <div className="footer-section">
        <div className="footer-container">  
            <div className="left-footer">
                <div className="address item-footer">
                    <div className="icon-footer">
                    <a href="https://maps.app.goo.gl/GwEWn1F7Be8BCMTP6?g_st=ic" target='_blank'><PinDropRounded fontSize='large'/></a>
                    </div>
                    <div className="info-footer">
                        <a href="https://maps.app.goo.gl/GwEWn1F7Be8BCMTP6?g_st=ic" target='_blank'><p>Melbourne - Hawthorn 3122</p></a>
                    </div>
                </div>
                <div className="wp item-footer">
                    <div className="icon-footer">
                    <a href="https://api.whatsapp.com/send?phone=61474009518" target='_blank'><img src={iconWp} alt="Whattsapp" /></a>
                    </div>
                    <div className="info-footer">
                        <a href="https://api.whatsapp.com/send?phone=61474009518" target='_blank'>+61 474 009 518</a>
                    </div>
                </div>
                <div className="mail item-footer">
                    <div className="icon-footer">
                        <LocalPostOfficeRounded fontSize='large'/>
                    </div>
                    <div className="info-footer">
                        <p>admin@greensuncleaning.com</p>
                    </div>
                </div>
            </div>
            <div className="rigth-footer">
                <div className="map-site">
                    <ul>
                        <li><Link to='/'>HOME</Link></li>
                        <li><Link to='/services-us'>SERVICES</Link></li>
                        <li><Link to="/about">ABOUT US</Link></li>
                        <li><Link to='/contact-us'>CONTACT US</Link></li>
                    </ul>
                </div>
                <div className="socials">
                    <div className="social-item">
                        <a href="https://api.whatsapp.com/send?phone=61474009518" target='_blank'>
                            <img src={iconWp} alt="Whattsapp" />
                        </a>
                    </div>
                    <div className="social-item">
                        <a href="https://www.facebook.com/profile.php?id=61550993527658" target='_blank'>
                            <img src={iconFb} alt="Facebook" />
                        </a>
                    </div>
                    <div className="social-item">
                        <a href="https://www.instagram.com/greensuncleaning/" target='_blank'>
                            <img src={iconIg} alt="Instagram" />
                        </a>
                    </div>
                </div>
                <div className="arrobado">
                    <h4>@greensuncleaning</h4>
                </div>
            </div>
            <div className="float-footer">
                <img src={Logo} alt="Logo"/>
            </div>
        </div>
    </div>
  );
};

export default Footer;