import React from "react";
import imgLanding from "../assets/landingContact.png";
import ContactForm from "../forms/FormContac";
import SliderService from "./SliderServices";
import Faqs from "../components/faqs/ContainerFaqs";

interface SliderPropsContact {
    regular?: boolean;
    dataService: any;
  }

const ContactUs = ({dataService, regular}: SliderPropsContact) => {
    return (
        <div className="container-principal-contact">
            <div className="section-first-form">
                <div className="left-contact">
                    <h2>Request a Cleaning Service</h2>
                    <p></p>
                    <div className="img-form">
                        <img src={imgLanding} alt="Mailing Contact" />
                    </div>
                </div>
                <div className="rigth-contact">
                    <ContactForm/>
                </div>
            </div>
            <div className="add-section">
                <SliderService data={dataService} regular={regular}/>
                <Faqs/>
            </div>
        </div>
    )
}

export default ContactUs;