import React from 'react';
import Layout from '../components/Layout';
import AboutUs from '../assets/teamAbout.jpg'
import AboutUs2 from '../assets/mission.jpg'
import AboutUs3 from '../assets/vision.jpg'
import AboutUs4 from '../assets/aboutus4.png'

interface PropsAbout {
  regular?: boolean
}

function About({regular}: PropsAbout) {
  return (
    <div className="page-about">
      <Layout regular={regular}>
          <div className="container-about">
            <h1>About Us</h1>
            <div className="first-section section-about">
              <div className="top-section">
                <div className="img-section">
                  <img src={AboutUs} alt="About Us" />
                </div>
                <p>Greensun Eco-Friendly Cleaning Services was founded in 2023 in Melbourne, Australia. 

                The inspiration behind the company came from our passion for environmental conservation and the desire to make a positive impact on the cleaning industry. 

                We recognized the lack of eco-friendly cleaning services in the market and saw an opportunity to create a company that would offer high-quality cleaning solutions while promoting sustainability and environmental responsibility.</p>
              </div>
              <div className="bottom-section">
                <p>Starting with a small team of dedicated professionals, Greensun Eco-Friendly Cleaning Services quickly gained traction in the local market by delivering exceptional cleaning services with a focus on eco-friendly practices. Over the years, the company's reputation for reliability, excellent customer service, and commitment to environmental conservation led to steady growth and an expanding customer base.

                Today, Greensun Eco-Friendly Cleaning Services stands as a well-established cleaning company in Melbourne, known for its unwavering dedication to providing eco-conscious cleaning solutions to businesses and residents alike.</p>
              </div>
            </div>
            <div className="second-section section-about">
              <div className="text-section-about">
                <h4>Mission</h4>
                <p>Our mission at Greensun Eco-Friendly Cleaning Services is to offer sustainable and reliable cleaning services, promoting a healthier environment and enhancing the well-being of our clients and communities. 

                We are committed to delivering exceptional customer experiences while minimizing our ecological footprint through the use of eco-friendly products and practices.</p>
              </div>
              <div className="img-section-about">
                <img src={AboutUs2} alt="About Us" />
              </div>
            </div>
            <div className="third-section section-about">
              <div className="img-section-about">
                <img src={AboutUs3} alt="About 3" />
              </div>
              <div className="text-section-about">
                <h4>Vision</h4>
                <p>Our vision is to become a leading provider of eco-friendly cleaning services in Melbourne,recognized for our unwavering commitment to environmental responsibility and premium service quality. We aim to be the preferred choice for clients seeking a cleaner, healthi</p>
              </div>
            </div>
            <div className="fourth-section section-about">
              <div className="section-especial">
              <div className="img-section-about">
                <img src={AboutUs4} alt="About 4" />
              </div>
              <div className="text-section-about">
                <h4>Legal Structure</h4>
                <p>Greensun Eco-Friendly Cleaning Services is a legally registered and fully insured cleaning company in Melbourne, Australia. We operate as a private limited liability company, ensuring legal protection for both the company and its stakeholders. 

                This structure allows us to conduct business operations in accordance with all local laws, regulations, and industry standards, while providing a solid foundation for sustainable growth and financial stability.</p>
              </div>
              </div>
            </div>
            <div className="fifth-section section-about">
              <h5></h5>
              <p>Greensun Eco-Friendly Cleaning Services is dedicated to offering exceptional eco-friendly cleaning solutions to clients in Melbourne. By integrating sustainable practices, eco-friendly products, and premium customer service, we aim to create a cleaner, healthier, and more sustainable environment for our clients and communities.</p>

              <p>As we embark on this journey, we commit ourselves to excellence, environmental responsibility, and continuous improvement. With a focus on meeting the needs of our customers and delivering eco-conscious cleaning services, we are confident in our ability to achieve success and make a positive impact in the cleaning services industry.</p>

              <p>Please note that this business plan serves as a general framework, and you may need to customize it further to match your specific business requirements and conduct thorough market research to validate your financial projections. Seek professional advice if necessary, and ensure that your business plan aligns with your long-term objectives and values. Good luck with your </p>
              
              <span>Greensun Eco-Friendly Cleaning Services venture in Melbourne!</span>
            </div>
          </div>
      </Layout>
    </div>
  );
}

export default About;