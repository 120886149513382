import React from "react";
import imgPricing from '../assets/pricingService.jpg'

const Princing = () => {
    return (
        <div className="PrincingContainer ContainerDefault">
            <div className="content-section">
                <div className="title-section">
                    <h2>Pricing</h2>
                </div>
                <div className="body-section">
                    <div className="text-section">
                    Our pricing is competitive and tailored to the specific requirements of each service package. We offer transparent and upfront pricing, allowing clients to choose the cleaning plan that best fits their needs and budget. The cost of our eco-friendly services is justified by the added value of promoting a healthier environment and supporting sustainability initiatives.
                    </div>
                    <div className="img-section">
                        <img src={imgPricing} alt="Imagen de la sección de precios"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Princing;