import services1 from "../src/assets/comercialCleaning300424.jpg";
import services2 from "../src/assets/builder_service300424.jpg";
import services3 from "../src/assets/resedentialCleaning300424.jpg";
import services4 from "../src/assets/IndustrialCleaningServices.jpg";
import services5 from "../src/assets/windowCleaning.jpg";
import services6 from "../src/assets/DisinfectionCleaningServices.jpg";
import services8 from "../src/assets/endOfLease300424.jpg";
import services9 from "../src/assets/windowsCleaningConstruction300424.jpg";
import services10 from "../src/assets/carpetCleaning300424.jpg";
import services11 from "../src/assets/ovenServices.jpg";
import officeCleaning from "../src/assets/officeCleaning300424.jpg"

export const questions = [
  {
    id: 1,
    question: "What do you include in your home cleaning package?",
    answer: `We clean, dust, mop and scrub whatever needs to be done, including:<br><br>
        <ul>
          <li>Dusting all surfaces, including baseboards, window sills, and ceiling fans</li>
          <li>Vacuuming carpets and floors</li>
          <li>Wiping of counters, sinks and all appliances including ovens</li>
          <li> Scrubbing of bathrooms, including toilets, showers and tiles</li>
          <li>Polishing mirrors, windows and furniture</li>
        </ul>
      <br><br>We will move furniture for cleaning depending on its weight. If the furniture is too heavy to lift, we will endeavor to clean under and around it as much as practically possible.
      Our team can perform special cleaning duties on demand - just let us know what needs doing, and we’ll take care of it!
      `,
  },
  {
    id: 2,
    question: "What cleaning equipment is used?",
    answer: `At Green Sun, we use the highest quality cleaning products and top-end professional equipment to ensure excellent results every time.<br><br>Our cleaner will bring all required cleaning agents, equipment and machines. If you prefer that a particular item be used (or not used), please inform us and we will make the necessary arrangements.<br><br>
      If special equipment is required (eg - a ladder to reach high places) please inform us at the time of booking. 
      `,
  },
  {
    id: 3,
    question: "Can I add steam cleaning to my booking?",
    answer: "Of course - just call us and let us know!",
  },
  {
    id: 4,
    question: "Do you provide end-of-lease cleaning?",
    answer:
      "Yes - we’ll make sure your home or office is as clean as possible before handing back the keys.",
  },
  {
    id: 5,
    question: "What kind of windows do you clean?",
    answer:
      "We can clean all types of residential and commercial, exterior and interior windows.",
  },
  {
    id: 6,
    question:
      "Do you remove paint from glass?Can I add steam cleaning to my booking?",
    answer:
      "Yes, although additional fees may apply if extra cleaning work is required.",
  },
  {
    id: 7,
    question: "Will there be streaks on my windows after cleaning?",
    answer:
      "No, our cleaners take special care to ensure that your windows are clean and crystal clear!",
  },
  {
    id: 8,
    question: "Does your company do any exterior work?",
    answer:
      "Yes! Windows, walls, facades… we can provide a quote for them all! Exterior window cleaning is a separate job which requires a separate quote, and depends on accessibility. If scaffolding is required, this can be discussed at the time of booking, and will incur an additional fee. Window ledges and sills are cleaned, but window tracks are not included – they can be included for cleaning at extra cost.",
  },
  {
    id: 9,
    question: "What if it rains when exterior cleaning is booked?",
    answer:
      "External cleaning will need to be rescheduled for another day in case of rain.",
  },
];
export const questionsBooking = [
  {
    id: 1,
    question: "Why hire a cleaning service?",
    answer: `<br>
        <ul>
          <li>Spend less time cleaning and more time doing what you would rather be doing - leave the cleaning to us!</li>
          <li>A deeper clean - we’ll make sure your home isn’t just clean, but spick and span!</li>
          <li>A more thorough clean - using the best equipment available, Green Sun makes sure it does just look clean, it is as clean as possible!</li>
        </ul>
      <br>
      `,
  },
  {
    id: 2,
    question: "When are you available?",
    answer: `We can travel to you anywhere across metropolitan Melbourne and surrounding areas and regional centres. Whenever you would like us to visit, just let us know and we’ll be there! We aim to work as efficiently as possible while still maintaining the highest standards of care, saving you time and money.
    <br/><br/>Our cleaners arrive at a date and time pre-arranged in consultation with you - whenever best suits your busy schedule.
    `,
  },
  {
    id: 3,
    question: "How long does it take one person to clean a house?",
    answer: "This depends on the size of the home, and how much cleaning is required! An average sized home will usually take 3 to 4 hours, however sometimes more or less time is needed. If required, we will send a second team member to assist.",
  },
  {
    id: 4,
    question: "How often will you visit?",
    answer:
      "We can come as often as you like! Most householders opt for a clean once every two weeks. <br/><br/>Windows, especially exterior windows, usually require cleaning approximately every 3 months. Our cleaners use the highest quality cleaning agents to ensure there is no residue which might attract dust or dirt after cleaning.",
  },
  {
    id: 5,
    question: "Do you work on the weekend?",
    answer:
      "Yes, we do.",
  },
  {
    id: 6,
    question:
      "Do I have to do anything before the service?",
    answer:
      "We will discuss access to your home. Access to water will be required, and for exterior cleaning this can be discussed at the time of booking the appointment. If you have pets, please advise at the time of booking and we can discuss necessary arrangements. If you are at home while we clean, we’ll do our very best not to disturb you.",
  },
  {
    id: 7,
    question: "Do I need to stay home during the cleaning?",
    answer:
      "It’s up to you! For external windows, you do not need to be home. For internal work we'll be happy to clean your home whenever is most convenient for you - if required, you can give us a key and we will clean your home while you’re out! All of our staff are highly trustworthy and have passed background checks.",
  },
  {
    id: 8,
    question: "Does your company provide invoices for corporate work?",
    answer:
      "Yes - get in contact with us and we’ll be happy to organise a quote for your company’s cleaning job.",
  },
];
export const questionsSafety = [
  {
    id: 1,
    question: "If I give the cleaner a key, will it be safe?",
    answer: `Yes. Although it’s not always necessary, sometimes you might require us to access your home/premises independently. We will make sure to keep your key absolutely safe and secure, and just as we found it, but cleaner!`,
  },
  {
    id: 2,
    question: "Do your cleaners have police checks?",
    answer: `Yes, all of our cleaners have police checks and working with children checks too.`,
  },
  {
    id: 3,
    question: "Will I get the same cleaner for each service?",
    answer: "We try to schedule the same cleaner on for each service as this allows them to be familiar with the requirements of the home/premises. Occasionally, in case of illness or unavailability, a different cleaner may be sent to your home, but we aim to minimise these occurrences. If this happens, you will be informed before the cleaner arrives.",
  },
];
export const questionsCost = [
  {
    id: 1,
    question: "How do I pay for my service?",
    answer: `For all bookings, a deposit must be made on the website by debit/credit card. Final payment can be made by cash, debit/credit card or bank transfer.`,
  },
  {
    id: 2,
    question: "How much does a cleaner cost?",
    answer: `Different levels of cleaning and different sizes of homes require different equipment, so quotes are given depending on the scale of each job. Our rates are super competitive, so get in touch today to get a quote!`,
  },
  {
    id: 3,
    question: "Is a deposit required?",
    answer: "A deposit is required at the time of booking to ensure consistency of service by our team members. The deposit is deducted from the final cost of the cleaning service. In the event of cancellation, the full deposit will be returned if you cancel more than 24 hours before the arranged date and time.",
  },
];
export const questionsC = [
  {
    id: 1,
    question: "How can I contact Green Sun to enquire about my service?",
    answer: `Give us a call, send us a text or email us on these details:<br><br>
        <ul>
          <li>Phone:</li>
          <li>Email:</li>
        </ul>`,
  },
  {
    id: 2,
    question: "How do I make changes to my booking?",
    answer: `Give us a call, send us a text or email us on the details listed above`,
  },
  {
    id: 3,
    question: "How can I cancel my service?",
    answer: "Give us a call, send us a text or email us on the details listed above.<br/><br/>If you cancel prior to 24 hours before your service, there is no cancellation fee, and any deposit you have paid will be returned.<br/><br/>Cancellations within 24 hours of the appointment time will incur a 50% cancellation fee, deducted from the deposit.",
  },
  {
    id: 4,
    question: "What if I don’t have parking available for the cleaners?",
    answer:
      "Generally, we require parking within 100 metres walk of the cleaning site. If you do not have free parking available within 100 metres walk, please inform us at the time of booking. If payment is required for our team’s parking, this may affect the cost of the quote.",
  },
  {
    id: 5,
    question: "What if I am not happy with my service?",
    answer:
      "If you are not satisfied with any aspect of our cleaning service, please take photographs of the issue and get in contact with us within 2 days of our visit. We will then provide a free re-clean as soon as possible to make sure it meets the expectations we’re known for!",
  },
  {
    id: 6,
    question:
      "What happens if damage is caused during the cleaning service?",
    answer:
      "We treat your home and its contents with the utmost care, however very occasionally accidents happen. In this instance please take photographs of the damage and get in contact with us within 2 days of our visit for an assessment. If the damage was caused by one of our team members, we will cover the cost of repair - up to 3 quotes for repair may be required. When a repair is not possible, a suitable replacement will be sought, and proof of purchase will be required. All quotes and proof of purchase must be provided within 30 days of the complaint being lodged.",
  },
  {
    id: 7,
    question: "Are you insured?",
    answer:
      "Yes, the cleaners are fully insured.",
  },
];

export const services = [
  {
    id: 1,
    title: "Commercial Cleaning Services",
    description: "<strong>Features:</strong> Customizable cleaning plans for offices, retail spaces, and commercial establishments. Regular cleaning schedules and deep cleaning options available.",
    benefits: "<strong>Benefits:</strong> A clean and welcoming work environment that promotes employee productivity, boosts business reputation, and creates a positive first impression for clients and visitors.",
    img: services1,
    img_alt: "Commercial Cleaning Services"
  },
  {
    id: 2,
    title: "End-of-Lease Cleaning",
    description: "<strong>Features:</strong> Our End-of-Lease Cleaning service is your partner in securing the return of your security deposit and ensuring a hassle-free transition during the end of your lease.",
    benefits: "<strong>Benefits:</strong> Security Deposit Return,Don't let the stress of end-of-lease cleaning stand in your way. Our End-of-Lease Cleaning service is designed to provide you with a clean, fresh, and welcoming space as you hand back the keys to your landlord. Contact us today to schedule your service and experience the convenience of a professional and reliable end-of-lease cleaning solution.",
    img: services8,
    img_alt: "End-of-Lease Cleaning"
  },
  {
    id: 3,
    title: "Office Cleaning",
    description: "<strong>Features:</strong> Customized cleaning plans for corporate offices, including workstations, conference rooms, and common areas.",
    benefits: "<strong>Benefits:</strong> A clean and organized workspace that enhances employee morale, productivity, and overall well-being.",
    img: officeCleaning,
    img_alt: "Office Cleaning",
  },
  {
    id: 4,
    title: "Builders Cleaning",
    description: "<strong>Features:</strong> Thorough post-construction cleaning services for newly built or renovated properties. Removal of debris, dust, and construction-related waste.",
    benefits: "<strong>Benefits:</strong> Quick and efficient cleaning that prepares the property for occupancy, ensuring a safe and clean space for clients or tenants.",
    img: services2,
    img_alt: "Builders Cleaning"
  },
  {
    id: 5,
    title: "Window Cleaning for Construction",
    description: "<strong>Features:</strong> Our Window Cleaning for Construction service is designed to ensure that your newly constructed or renovated building looks its best by providing pristine, spotless windows. Whether it's a residential construction project or a commercial development, clean windows can greatly enhance the appearance and appeal of the structure.",
    benefits: "<strong>Benefits:</strong> Enhanced Building Presentation: Clean, clear windows contribute to a well-presented and professional appearance, which is especially important for commercial properties, also, Saves Time and Resources: Our professional team handles the window cleaning, allowing your construction crew to focus on their tasks.",
    img: services9,
    img_alt: "Window Cleaning for Construction"
  },
  {
    id: 6,
    title: "Window Cleaning Services",
    description: "<strong>Features:</strong> Exterior and interior window cleaning for residential and commercial properties. Utilizes eco-friendly cleaning agents for a streak-free shine.",
    benefits: "<strong>Benefits:</strong> Improves natural lighting, enhances the building's appearance, and promotes a cleaner and more pleasant indoor environment.",
    img: services5,
    img_alt: "Window Cleaning Services"
  },
  {
    id: 7,
    title: "Carpet Cleaning Service",
    description: "<strong>Features:</strong> Our Carpet Cleaning Service is designed to restore the vibrancy, cleanliness, and longevity of your carpets. Whether in a residential or commercial setting, clean carpets contribute to a healthier indoor environment and a positive aesthetic appeal. We employ industry-leading techniques and eco-friendly products to ensure your carpets look and feel their best.",
    benefits: "<strong>Benefits:</strong> Professional cleaning extends the lifespan of your carpets, protecting your investment and improving aesthetics, clean carpets enhance the visual appeal of your space, making it more inviting and presentable. Also, We effectively remove stains and odors, ensuring a fresh and odor-free carpet.",
    img: services10,
    img_alt: "Carpet Cleaning Service"
  },
  {
    id: 8,
    title: "Industrial Kitchen Cleaning",
    description: "<strong>Features:</strong> Specialized cleaning for commercial kitchens, including degreasing, sanitization, and compliance with health and safety standards.",
    benefits: "<strong>Benefits:</strong> Ensures a hygienic and safe environment for food preparation, reduces fire hazards, and maintains compliance with health regulations.",
    img: services4,
    img_alt: "Industrial Kitchen Cleaning"
  },
  {
    id: 9,
    title: "Residential Cleaning",
    description: "<strong>Features:</strong> Comprehensive house cleaning services for homeowners and tenants. Cleaning of living spaces, kitchens, bathrooms, and bedrooms",
    benefits: "<strong>Benefits:</strong> A tidy and fresh living space that enhances comfort, reduces allergens, and promotes a healthier living environment.",
    img: services3,
    img_alt: "Residential Cleaning"
  },
  {
    id: 10,
    title: "Oven and BBQ Cleaning",
    description: "<strong>Features:</strong> Our Oven and BBQ Cleaning service is your solution for pristine and hygienic kitchen appliances. Over time, ovens and BBQs accumulate grease, grime, and burnt-on residue, affecting their efficiency and the taste of your cooked food. We specialize in thorough and environmentally friendly cleaning to restore your appliances to their optimal condition.",
    benefits: "<strong>Benefits:</strong> Enhanced Appliance Efficiency, clean ovens and BBQs perform better, providing improved cooking results and saving energy, also, Cooking in a clean oven or on a clean BBQ enhances the taste and quality of your meals. Our use of environmentally friendly cleaning products aligns with modern sustainability goals. Additionally, regular cleaning ensures that your kitchen appliances remain hygienic and safe for food preparation and say goodbye to lingering odors and hello to fresh, odor-free cooking.",
    img: services11,
    img_alt: "Oven and BBQ Cleaning"
  },
  {
    id: 11,
    title: "Disinfection Cleaning Services",
    description: "<strong>Features:</strong> Thorough cleaning and disinfection to eliminate germs and bacteria, especially in high-touch areas and communal spaces.",
    benefits: "<strong>Benefits:</strong> Provides a safe and germ-free environment, reducing the risk of illnesses and promoting peace of mind for clients and occupants.",
    img: services6,
    img_alt: "Disinfection Cleaning Services"
  }
]

export const servicesRegular = [
  {
    id: 1,
    title: "Commercial Cleaning Services",
    description: "<strong>Features:</strong> Comprehensive cleaning solutions for commercial spaces, including offices, retail outlets, and other business establishments.",
    benefits: "<strong>Benefits:</strong> Enhanced workplace hygiene, improved employee morale, and a positive impression on clients and visitors. <br/><br/> <strong>Prices:</strong> Competitive pricing packages tailored to the size and needs of the commercial space.",
    img: services1,
    img_alt: "Commercial Cleaning Services"
  },
  {
    id: 2,
    title: "End-of-Lease Cleaning",
    description: "<strong>Features:</strong> Detailed cleaning to meet the standards required for the end of a lease.",
    benefits: "<strong>Benefits:</strong> Ensures the return of security deposits, facilitates a smooth transition for tenants, and leaves a positive impression on landlords. <br/><br/> <strong>Prices:</strong> Transparent pricing aligned with the specific needs of end-of-lease cleaning.",
    img: services8,
    img_alt: "End-of-Lease Cleaning"
  },
  {
    id: 3,
    title: "Office Cleaning",
    description: "<strong>Features:</strong> Customized cleaning plans for corporate offices, including workstations, conference rooms, and common areas.",
    benefits: "<strong>Benefits:</strong> A clean and organized workspace that enhances employee morale, productivity, and overall well-being. <br/><br/> <strong>Prices:</strong> Affordable residential cleaning packages, customizable based on the size and specific requirements of the home.",
    img: officeCleaning,
    img_alt: "Office Cleaning"
  },
  {
    id: 4,
    title: "Builders Cleaning",
    description: "<strong>Features:</strong> Post-construction cleaning services to remove debris, dust, and residues, ensuring a clean and safe environment.",
    benefits: "<strong>Benefits:</strong> Swift transformation from construction site to a ready-to-use space, contributing to client satisfaction and safety. <br/><br/> <strong>Prices:</strong> Transparent and affordable pricing based on the scope and scale of the post-construction cleanup.",
    img: services2,
    img_alt: "Builders Cleaning"
  },
  {
    id: 5,
    title: "Window Cleaning Services",
    description: "<strong>Features:</strong> Exterior and interior window cleaning for commercial and residential properties.",
    benefits: "<strong>Benefits:</strong> Enhanced natural light, improved aesthetics, and a pristine view from windows. <br/><br/> <strong>Prices:</strong> Transparent pricing based on the number and size of windows, ensuring affordability for all clients.",
    img: services5,
    img_alt: "Window Cleaning Services"
  },
  {
    id: 6,
    title: "Carpet Cleaning Service",
    description: "<strong>Features:</strong> Professional cleaning for carpets, removing stains, allergens, and odors.",
    benefits: "<strong>Benefits:</strong> Prolongs the life of carpets, enhances indoor air quality, and improves the overall appearance of the space.",
    img: services10,
    img_alt: "Carpet Cleaning Service"
  },
  {
    id: 7,
    title: "Industrial Kitchen Cleaning",
    description: "<strong>Features:</strong> Specialized cleaning for industrial kitchens, including equipment, surfaces, and exhaust systems.",
    benefits: "<strong>Benefits:</strong> Compliance with hygiene standards, prevention of fire hazards, and improved equipment lifespan. <br/><br/> <strong>Prices:</strong> Competitive rates for thorough and efficient industrial kitchen cleaning services.",
    img: services4,
    img_alt: "Industrial Kitchen Cleaning"
  },
  {
    id: 8,
    title: "Residential Cleaning",
    description: "<strong>Features:</strong> Tailored cleaning services for homes and apartments, covering all rooms and surfaces.",
    benefits: "<strong>Benefits:</strong> A clean and healthy living environment, freeing up time for clients to focus on other priorities. <br/><br/> <strong>Prices:</strong> Affordable residential cleaning packages, customizable based on the size and specific requirements of the home.",
    img: services3,
    img_alt: "Residential Cleaning"
  },
  {
    id: 9,
    title: "Oven and BBQ Cleaning",
    description: "<strong>Features:</strong> Thorough cleaning of ovens and BBQs, removing grease, grime, and residue.",
    benefits: "<strong>Benefits:</strong> Improved appliance efficiency, better-tasting food, and a safer cooking environment. <br/><br/> <strong>Prices:</strong> Transparent pricing based on the type and size of appliances.",
    img: services11,
    img_alt: "Oven and BBQ Cleaning"
  },
  {
    id: 10,
    title: "Disinfection Cleaning Services",
    description: "<strong>Features:</strong> Thorough disinfection of spaces to eliminate germs, viruses, and bacteria.",
    benefits: "<strong>Benefits:</strong> Healthier environments, especially crucial in times of health concerns, and assurance of safety. <br/><br/> <strong>Prices:</strong> Competitive pricing for regular and one-time disinfection services.",
    img: services6,
    img_alt: "Disinfection Cleaning Services"
  },
  {
    id: 11,
    title: "Office Cleaning Services",
    description: "<strong>Features:</strong> Tailored cleaning solutions for offices, promoting a clean and organized work environment.",
    benefits: "<strong>Benefits:</strong> Boosts employee productivity, creates a positive work atmosphere, and leaves a professional impression on clients. <br/><br/> <strong>Prices:</strong> Competitive rates for routine and customized office cleaning packages. Unique Technology or Intellectual Property",
    img: services11,
    img_alt: "Office Cleaning Services"
  }
]

