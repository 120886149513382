import React from "react";
import Layout from "../components/Layout";
import DetailService from "../components/services/Detail";
import { useParams } from "react-router-dom";

interface DataProps {
  data: any;
  regular?: any;
}

function ServiceCont({data, regular}: DataProps) {
  const { id } = useParams();
  if (id === undefined) {
    return <div>Service not found</div>;
  }
  const serviceIndex = parseInt(id, 10);
  if (
    isNaN(serviceIndex) ||
    serviceIndex < 0 ||
    serviceIndex >= data.length
  ) {
    return <div>Invalid service id</div>;
  }

  return (
    <div className="detail-service">
      <Layout regular={regular}>
        <DetailService
          imagen={data[serviceIndex].img}
          title={data[serviceIndex].title}
          description={data[serviceIndex].description}
          benefits={data[serviceIndex].benefits}
          data={data}
          regular={regular}
        />
      </Layout>
    </div>
  );
}

export default ServiceCont;
