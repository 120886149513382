import axios from 'axios';

const clientApiAxios = axios.create({
   baseURL: `https://sendmailer-rho.vercel.app`,
   headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
   },
});

const URI = '/enviar-email';
export const sendMailConfirmation = (data: any) => {
   return new Promise((resolve, reject) => {
      clientApiAxios
         .post(URI, data)
         .then((response: any) => {
            resolve(response.data);
         })
         .catch((error: any) => reject(error));
   });
};

