import Logo from '../assets/LogoGreenSun.svg';
import legengHead from '../assets/fraseHeaderV.svg';
import legengHeadRegular from '../assets/fraseHeaderRegular.svg';
import { Link } from 'react-router-dom';
import { Box, Modal } from '@mui/material';
import { useState } from 'react';
import ContactForm from '../forms/FormCotizar';
import imgLanding from "../assets/landingContact.png";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

const ModalStyle = styled(Modal)`
   overflow: scroll;
`;

interface PropsHeader {
  regular?: boolean;
}

const Header = ({regular}: PropsHeader) => {
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenM = () => setOpenMenu(true);
  const handleCloseM = () => setOpenMenu(false);

  
  return (
    <div className="HeaderContainer">
      <div className="top-header">
        <div className="content-topheader">
          <div className="logo-page"><Link to={regular ? '/regular' : '/'}><img src={Logo} alt="Logo"/></Link></div>
          <div className="central-leyend">
            <img src={regular ? legengHeadRegular : legengHead} alt="Legend Header"/>
          </div>
          <div className="cotizaciones" onClick={handleOpen}>REQUEST A QUOTE</div>
          <div className="menu-burguer" onClick={handleOpenM}><MenuIcon fontSize='large'/></div>
        </div>
        <ModalStyle
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="cotainer-modal">
            <div className="section-first-form">
                <div className="left-contact">
                    <h2>Request a Cleaning Service</h2>
                    <p></p>
                    <div className="img-form">
                        <img src={imgLanding} alt="Mailing Contact" />
                    </div>
                </div>
                <div className="rigth-contact">
                    <ContactForm/>
                </div>
            </div>
          </Box>
        </ModalStyle>
      </div>
      <div className={openMenu ? 'bottom-header openMobile': 'bottom-header closeMobile'}>
        <div className="head-menu-mobile">
          <div className="close-menu-mobile" onClick={handleCloseM}><CloseIcon fontSize='large'/></div>
          <div className="logo-menumobile"><Link to={regular ? '/regular' : '/'}><img src={Logo} alt="Logo"/></Link></div>
          <div className="cotizaciones" onClick={handleOpen}>REQUEST A QUOTE</div>
        </div>
        <div>
            <ul>
                <li><Link to={regular ? '/regular' : '/'}>HOME</Link></li>
                <li><Link to={regular ? '/regular/services-us' : '/services-us'}>SERVICES</Link></li>
                <li><Link to={regular ? '/regular/about' : '/about'}>ABOUT US</Link></li>
                <li><Link to={regular ? '/regular/contact-us' : '/contact-us'}>CONTACT US</Link></li>
            </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
