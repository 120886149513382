import React from 'react';
import Layout from '../components/Layout';
import CardService from '../components/Card';
//import { services } from '../data';

interface DataProps {
    data: any;
    regular: boolean;
}

function Services({data, regular}: DataProps) {
  return (
    <div className="page-services">
      <Layout regular={regular}>
          <div className="container-services">
          <div className="title-section">
            <h1>Services</h1>
          </div>
          <div className='content-section-services'>
            {data.map((item: any, key: any) => {
                return (
                    <CardService
                        id={item.id}
                        imagen={item.img}
                        title={item.title}
                        description={item.description}
                        regular={regular}
                    />
                )
            })}
          </div>
          </div>
      </Layout>
    </div>
  );
}

export default Services;