import React, {useState} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { sendMailConfirmation } from "../api/services";
import { LoadingButton } from "@mui/lab";

const namesStates = [
  'Request a service',
  'Discuss an existing booking',
  'Business partnership',
  'Want to join the team',
  'Leave feedback'
];

function CotizarForm() {
  const { handleSubmit, control, formState: { errors, isValid } } = useForm();
  const [loading, setLoading] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(false);
  const [className, setClassname] = useState("messageOculto")

  const onSubmit = (datos: any) => {
    setLoading(true)
    setMessageSuccess(false);
    const data = {
      destinatario: "admin@greensuncleaning.com",
      asunto: "¡Hola desde Greensun!",
      mensaje: `Ha escrito ${datos.nombre}, con la razón: ${datos.comoTePodemosAyudar} y los siguientes datos: Celular: ${datos.celular}, Email: ${datos.email} y Mensaje: ${datos.mensaje}`,
      mensajeHtml: `
    <p><strong>Jimmy</strong>, llegó un nuevo correo con los siguientes datos:</p>
    <ul>
      <li><strong>Nombre:</strong> ${datos.nombre}</li>
      <li><strong>Razón:</strong> ${datos.comoTePodemosAyudar}</li>
      <li><strong>Teléfono:</strong> ${datos.celular}</li>
      <li><strong>Correo:</strong> ${datos.email}</li>
      <li><strong>Código Postal:</strong> ${datos.postcode}</li>
      <li><strong>Mensaje:</strong> ${datos.mensaje}</li>
    </ul>
  `,
    };
    sendMailConfirmation(data)
      .then((res: any) => {
        console.log(res);
        setMessageSuccess(true);
        setClassname("messageExitoso")
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={className}>
      Email has been sent successfully
      </div>
      <div className="item-form-normal">
        <label htmlFor="comoTePodemosAyudar">A Reason for contact:</label>
        <Controller
          name="comoTePodemosAyudar"
          control={control}
          defaultValue=""
          rules={{ required: "Este campo es obligatorio" }}
          render={({ field }) => (
            <Select
              {...field}
              fullWidth
              MenuProps={{ PaperProps: { sx: { maxHeight: 130 } } }}
            >
              {namesStates.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </div>
      <div className="item-form-normal">
        <label htmlFor="nombre">Name</label>
        <Controller
          name="nombre"
          control={control}
          defaultValue=""
          rules={{ required: "Este campo es obligatorio" }}
          render={({ field }) => <TextField {...field} type="text" fullWidth />}
        />
      </div>
      <div className="group-select">
        <div className="item-form-normal celular-form">
          <label htmlFor="celular">Phone</label>
          <Controller
            name="celular"
            control={control}
            defaultValue=""
            rules={{ required: "Este campo es obligatorio" }}
            render={({ field }) => <TextField {...field} type="text" />}
          />
        </div>
        <div className="item-form-normal email-form">
          <label htmlFor="email">Email</label>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: "Este campo es obligatorio",
              pattern: /^\S+@\S+$/i,
            }}
            render={({ field }) => <TextField {...field} type="text" />}
          />
        </div>
      </div>
      <div className="item-form-normal">
        <label htmlFor="nombre">Postcode</label>
        <Controller
          name="postcode"
          control={control}
          defaultValue=""
          rules={{ required: "Este campo es obligatorio" }}
          render={({ field }) => <TextField {...field} type="text" fullWidth />}
        />
      </div>
      <div className="item-form-normal">
        <label htmlFor="mensaje">More information about your request. Please, add your notes below</label>
        <Controller
          name="mensaje"
          control={control}
          defaultValue=""
          rules={{ required: "Este campo es obligatorio" }}
          render={({ field }) => (
            <TextField
              {...field}
              type="textarea"
              multiline
              rows={4}
              variant="outlined"
            />
          )}
        />
      </div>
      <LoadingButton
        type="submit"
        className="form-contactus"
        loading={loading}
        disabled={!isValid}
        >
        REQUEST A QUOTE
      </LoadingButton>
      <button  type="submit">
        
      </button>
    </form>
  );
}

export default CotizarForm;
