import React from "react";
import addValue1 from "../assets/trustworthy300424.jpg"
import addValue2 from "../assets/24hourservice300424.jpg"
import addValue3 from "../assets/ecofriendly300424.jpg"

const AddedValue = () => {
    return (
        <div className="AddedValueContainer ContainerDefault">
            <div className="content-section-complete">
                <div className="title-section">
                    <h2>Why Choose Us?</h2>
                    <p></p>
                </div>
                <div className="body-section">
                    <div className="step-seccion step-1">
                        <div className="floatline"></div>
                        <div className="content-step">
                            <div className="img-step">
                                <img src={addValue1} alt="Step 1" />
                            </div>
                            <div className="info-step">
                                <div className="number">
                                    <span>1</span>
                                </div>
                                <div className="title-step">
                                    <h3>Trustworthy and Reliable</h3>
                                </div>
                                <div className="description">
                                Don't worry about leaving a crucial task such as cleaning in the hands of strangers. Our client history is a speaking example of why our professional cleaners are trustworthy and reliable in their tasks.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="step-seccion step-2">
                        <div className="floatline"></div>
                        <div className="content-step">
                            <div className="img-step">
                                <img src={addValue2} alt="Step 1" />
                            </div>
                            <div className="info-step">
                                <div className="number">
                                    <span>2</span>
                                </div>
                                <div className="title-step">
                                    <h3>24 Hour Service</h3>
                                </div>
                                <div className="description">
                                    If you want an urgent appointment for your commercial or domestic place. Our customer support service is available round the clock and make sure to clear all your concerns and queries.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="step-seccion step-3">
                        <div className="floatline"></div>
                        <div className="content-step">
                            <div className="img-step">
                                <img src={addValue3} alt="Step 1" />
                            </div>
                            <div className="info-step">
                                <div className="number">
                                    <span>3</span>
                                </div>
                                <div className="title-step">
                                    <h3>Eco-Friendly</h3>
                                </div>
                                <div className="description">
                                With the eco-friendly cleaning, we take care that the products and chemicals we use are sustainable and harmless for the environment to encourage the green movement and prevent pollution.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddedValue;