import React, { useState } from "react";
import {
  questions,
  questionsBooking,
  questionsC,
  questionsSafety,
  questionsCost,
} from "../../data.js";
import Question from "./Question.tsx";

const Faqs = () => {
  const [currentData, setCurrentData] = useState(questions);

  const handleButtonClick = (newData: any) => {
    setCurrentData(newData);
  };

  const renderQuestions = () => {
    return currentData.map((item) => (
      <Question
        key={item.id}
        id={item.id}
        question={item.question}
        answer={item.answer}
      />
    ));
  };
  return (
    <section className="faq">
      <div className="title-section">
        <h2>FAQS</h2>
      </div>
      <div className="buttons-container">

          <div
            className="buttonFaqsChengue"
            onClick={() => handleButtonClick(questions)}
          >
            What the Offer
          </div>
          <div
            className="buttonFaqsChengue"
            onClick={() => handleButtonClick(questionsBooking)}
          >
            Booking
          </div>
          <div
            className="buttonFaqsChengue"
            onClick={() => handleButtonClick(questionsSafety)}
          >
            Safety
          </div>
          <div
            className="buttonFaqsChengue"
            onClick={() => handleButtonClick(questionsC)}
          >
            Contact
          </div>
          <div
            className="buttonFaqsChengue"
            onClick={() => handleButtonClick(questionsCost)}
          >
            Cost and payment
          </div>
        </div>
      {renderQuestions()}
    </section>
  );
};

export default Faqs;
