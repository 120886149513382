import React, { useState, useEffect }  from 'react';
import CardService  from '../components/Card';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface SliderProps {
  data: any;
  regular?: boolean;
}

const SliderService = ({data, regular}: SliderProps) =>  {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [centerSlidePercentage, setCenterSlidePercentage] = useState(33.33);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth < 600) {
      setCenterSlidePercentage(100);
    } else if (screenWidth < 900) {
      setCenterSlidePercentage(50);
    } else {
      setCenterSlidePercentage(33.33);
    }
  }, [screenWidth]);

  return (
    <div className="ServicesContainer ContainerDefault">
        <div className="content-section">
            <div className="title-section">
                <h2>Our Services</h2>
            </div>
            <Carousel 
                showArrows={true} 
                showStatus={false} 
                infiniteLoop={false}
                showIndicators={false}
                centerMode={true}
                centerSlidePercentage={centerSlidePercentage}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <div className="buttonBackCustom" onClick={onClickHandler} title={label}>
                        <ArrowBackIosNewIcon/>
                      </div>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <div className="buttonNextCustom" onClick={onClickHandler} title={label}>
                        <ArrowForwardIosIcon/>
                      </div>
                    )
                  }
                >
                {data.map((item: any) => {
                  return <CardService
                    id={item.id}
                    imagen={item.img}
                    title={item.title}
                    description={item.description}
                    regular={regular}
                  />
                })}
            </Carousel>
        </div>
    </div>
  );
}

export default SliderService;