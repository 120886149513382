import React from 'react';

interface TextProps {
    text: string
}

const TextBlock = ({text}: TextProps) => {
    return (
        <p className='content-text'>{text}.</p>
    )
}

export default TextBlock;