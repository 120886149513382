import React from 'react';
import { Link } from 'react-router-dom';

interface CardProps {
    id: number
    imagen: string;
    title: string;
    description: string;
    regular: any;
  }
  
const CardService: React.FC<CardProps> = ({ id, imagen, title, description, regular}) => {
  return (
    <div key={id} className="CardServiceContainer">
        <div className="content-card">
            <div className="img-card">
                <Link to={`/${regular ? "regular/detail-service" : "detail-service"}/${id - 1}`}><img src={imagen} alt="Imagen Ref" /></Link>
            </div>
            <div className="title-card">
              <h3><Link to={`/${regular ? "regular/detail-service" : "detail-service"}/${id - 1}`}>{title}</Link></h3>
            </div>
            <div className="description-card">
              <div dangerouslySetInnerHTML={{ __html: description }} />;
            </div>
            <div className="actions-card">
                <div className="more-button button-service">More</div>
                <div className="ask-button button-service">Ask us</div>
            </div>
        </div>
    </div>
  );
};

export default CardService;