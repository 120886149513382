import { useState } from "react";
import { AddRounded, RemoveRounded } from "@mui/icons-material";

interface QuestionPropsI {
    id: number;
    question: string;
    answer: string;
}

const Question = ({id, question, answer}: QuestionPropsI) => {
    const [isActive, setActive] = useState(false);
    const handleClick = () => {
        setActive(!isActive)
    }
    return(
        <>    
            <div className="question-wrapper">
                <div className='question' key={id}>
                    <h3 className={isActive? 'active' : ''}>{question}</h3>
                    <div className={isActive? 'active iconMasMenos' : 'iconMasMenos'} onClick={() => handleClick()}>
                        {isActive? <RemoveRounded fontSize="large"/>:<AddRounded fontSize="large"/>}
                    </div>    
                </div>
                <div className={isActive? 'answer active' : 'answer'}>
                <div dangerouslySetInnerHTML={{ __html: answer }} />;
                </div>
            </div>
            <div className='divider-line'></div>
        </>
        )
}

export default Question;