import React from 'react';
import TextBlock  from '../components/TextBlock.tsx';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Sustainability = () =>  {
  return (
    <div className="SustainabilityContainer ContainerDefault">
        <div className="content-section">
            <div className="title-section">
                <h2>Sustainability Initiatives</h2>
            </div>
            <Carousel 
                showArrows={true} 
                showStatus={false} 
                infiniteLoop={true}
                showIndicators={false}
                centerMode={true}
                centerSlidePercentage={90}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <div className="buttonBackCustom" onClick={onClickHandler} title={label}>
                        <ArrowBackIosNewIcon/>
                      </div>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <div className="buttonNextCustom" onClick={onClickHandler} title={label}>
                        <ArrowForwardIosIcon/>
                      </div>
                    )
                  }
                >
                <TextBlock
                    text="Eco-Friendly Products: Use only environmentally safe and biodegradable cleaning products to minimize environmental impact and reduce harmful chemical exposure."
                />
                <TextBlock
                    text="Waste Management: Implement recycling and waste reduction programs to minimize the amount of waste generated during cleaning operations."
                    />
                <TextBlock
                    text="Carbon Footprint Reduction: Encourage staff to use eco-friendly transportation options and optimize routes to reduce carbon emissions"
                />
                <TextBlock
                    text="Green Cleaning Techniques: Utilize sustainable cleaning methods that conserve water and energy while maximizing cleaning efficiency."
                />
            </Carousel>
        </div>
    </div>
  );
}

export default Sustainability;