import Layout from '../components/Layout';
import ContactUs from '../containers/ContactUs'

interface PropsContact {
  regular?: boolean;
  dataService: any;
}

function Contact({regular, dataService}: PropsContact) {
    return (
        <div className="container-home">
          <Layout regular={regular}>
              <ContactUs regular={regular} dataService={dataService}/>
          </Layout>
        </div>
      );
}

export default Contact;