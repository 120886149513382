import React from 'react';
import durationIcon from '../../assets/durationIcon.png'
import hoursIcon from '../../assets/hoursIcon.png'
import legacyIcon from '../../assets/legacyIcon.png'
import SliderService from '../../containers/SliderServices';

interface CardProps {
    imagen: string;
    title: string;
    description: string;
    benefits: string;
    data: any;
    regular?: boolean;
  }
  
const DetailService: React.FC<CardProps> = ({ imagen, title, description, benefits, data, regular}) => {
  return (
    <div className="container-services-detail">
        <div className="content-card">
            <div className="top-detail">
                <h1>{title}</h1>
                <p dangerouslySetInnerHTML={{ __html: description }} />
                <br />
                <p dangerouslySetInnerHTML={{ __html: benefits }} />
            </div>
            <div className="botom-detail">
                <div className="left-bottom-detail">
                    <div className="content-img-detail">
                        <img src={imagen} alt="Imagen de servicio X" />
                    </div>
                    <div className='price'><p>Request a quote</p></div>
                </div>
                <div className="rigth-bottom-detail">
                    <div className="content-characteristics">
                        <div className="item-characteristic">
                            <div className="point-special"></div>
                            <span>Customer care hotline</span>
                        </div>
                        <div className="item-characteristic">
                            <div className="point-special"></div>
                            <span>Flexible booking options</span>
                        </div>
                        <div className="item-characteristic">
                            <div className="point-special"></div>
                            <span>Customisable services</span>
                        </div>
                        <div className="item-characteristic">
                            <div className="point-special"></div>
                            <span>Cutting edge professional equipment</span>
                        </div>
                        <div className="item-characteristic">
                            <div className="point-special"></div>
                            <span>Fully insured and vetted technicians</span>
                        </div>
                        <div className="item-characteristic">
                            <div className="point-special"></div>
                            <span>Eco-Friendly sanitation methods (upon request)</span>
                        </div>
                        <div className="item-characteristic">
                            <div className="point-special"></div>
                            <span>Always prompt and reliable</span>
                        </div>
                        <div className="item-characteristic">
                            <div className="point-special"></div>
                            <span>Special discount options</span>
                        </div>
                        <div className="item-characteristic">
                            <div className="point-special"></div>
                            <span>More Text</span>
                        </div>
                    </div>
                    <div className="specifics-services">
                        <div className="group-div">
                            <div className="duration">
                                <img src={durationIcon} alt="Duración" />
                                <div className="detail-duration">
                                    <span>Duration</span>
                                    <p>Length of service</p>
                                </div>
                            </div>
                            <div className="hours">
                                <img src={hoursIcon} alt="Horarios" />
                                <div className="detail-hours">
                                    <span>Opening hours</span>
                                    <div className='special-content-hours'>
                                        <p>M - S</p>
                                        <p>8 AM <br/> 6 PM</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="legacy">
                            <img src={legacyIcon} alt="Legacy" />
                            <p>We reserve the right to change the price estimate on location if the property conditions are different from those described upon making a booking or the customer’s original requirements have altered.</p>
                        </div>
                    </div>
                    {/* <div className="other-info">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla rutrum sapien at commodo egestas. Fusce ac bibendum quam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla rutrum sapien at commodo egestas. Fusce ac bibendum quam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla rutrum sapien at commodo egestas. Fusce ac bibendum quam.  </p>
                    </div> */}
                </div>
            </div>
        </div>
        <div className="related-services">
            <SliderService data={data} regular={regular}/>
        </div>
    </div>
  );
};

export default DetailService;