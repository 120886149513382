import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home'; // Reemplaza con el componente de inicio de tu aplicación
import About from './pages/About';
import Contact from './pages/Contact';
import Services from './pages/Services';
import './App.css';
import ServiceCont from './containers/Service';
import { services, servicesRegular } from './data';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home dataService={services}/>} />
          <Route path="/regular" element={<Home dataService={servicesRegular} regular={true}/>} />
          <Route path="/services-us" element={<Services data={services} regular={false}/>} />
          <Route path="/regular/services-us" element={<Services data={servicesRegular} regular={true}/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/regular/about" element={<About regular={true}/>} />
          <Route path="/contact-us" element={<Contact dataService={services}/>} />
          <Route path="/regular/contact-us" element={<Contact regular={true} dataService={servicesRegular}/>} />
          <Route path="/detail-service/:id" element={<ServiceCont data={services}/>} />
          <Route path="regular/detail-service/:id" element={<ServiceCont data={servicesRegular} regular={true}/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
