import Banner from '../components/Banner';
import Layout from '../components/Layout';
import Faqs from '../components/faqs/ContainerFaqs';
import AddedValue from '../containers/AddedValue';
import Princing from '../containers/Pricing';
import SliderService from '../containers/SliderServices';
import Sustainability from '../containers/Sustainability';

interface PropsHome {
  regular?: any;
  dataService: any;
}

function Home({regular, dataService}: PropsHome) {
  return (
    <div className="container-home">
      <Layout regular={regular}>
          <Banner/>
          <SliderService data={dataService} regular={regular}/>
          {regular ? '' : <Sustainability/>}
          <Princing/>
          {regular ? '' : <AddedValue/>}
          <Faqs/>
      </Layout>
    </div>
  );
}

export default Home;